const React = require('react')
const { ContextProvider } = require('./src/components/Context')

exports.wrapPageElement = ({ element }) => {
  return (
    <ContextProvider>
      {element}
    </ContextProvider>
  )
}
