import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './Header'

import Context from './Context'
import schema from './../utils/ziglerSchema.js'
import './../scss/index.scss'

export default function Layout ({ children }) {
  const { azContext } = React.useContext(Context)
  const webPageSchema = {
    ...schema.webPageSchema,
    description: azContext.page_description,
    headline: azContext.page_title,
    image: azContext.page_image,
    name: azContext.page_title,
    thumbnailUrl: azContext.page_image,
    url: azContext.page_url
  }

  return (
    <div>
      <Helmet
        defaultTitle={azContext.title}
        defer={false}
      >
        <html lang="en" />
        <title>{azContext.page_title}</title>
        <meta name="description" content={azContext.page_description} />

        <meta name="og:type" content="website" />
        <meta name="og:locale" content="en_US" />
        <meta name="og:site_name" content={azContext.title} />
        <meta name="og:title" content={azContext.page_title} />
        <meta name="og:url" content={azContext.page_url} />
        <meta name="og:image" content={azContext.page_image} />
        <meta name="og:description" content={azContext.page_description} />

        <meta name="twitter:title" content={azContext.page_title} />
        <meta name="twitter:description" content={azContext.page_description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={azContext.page_image} />

        <script type="application/ld+json">
          {JSON.stringify(schema.webSiteSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(webPageSchema)}
        </script>
      </Helmet>

      <Header />

      <main>
        {children}
      </main>
    </div>
  )
}
