import React from 'react'
import Link from './Link'
import { Divide as Hamburger } from 'hamburger-react'

export default function Header () {
  const [collapsed, set] = React.useState(false)

  const navArray = ['/about/', '/now/', '/contact/', '/blog/', '/portfolio/']

  function makeNav ({ navArray, linkActiveClassName = 'active', linkClassNames = [], navClassNames = [] }) {
    const navClasses = navClassNames.map((className, index) => {
      const result = className + (index < navClassNames.length - 1 ? ' ' : '')
      return result
    }).join('')

    const buildNav = () => {
      return navArray.map(function (navItem, index) {
        return (
        <Link to={navItem} key={index} activeClassName={navItem !== '/' ? linkActiveClassName : ''} partiallyActive={navItem !== '/'} onClick={() => { set(false) }}>
          {navItem !== '/' ? navItem.slice(1, navItem.length - 1) : 'Andrew Zigler'}
        </Link>
        )
      })
    }

    return (<nav className={navClasses}>{buildNav()}</nav>)
  }

  const homeNav = makeNav({ navArray: ['/'], navClassNames: ['home'] })
  const desktopNav = makeNav({ navArray, navClassNames: ['desktop'] })
  const mobileNav = makeNav({ navArray })

  return (
    <header>
      {homeNav}
      {desktopNav}
      <div className={'drawer mobile' + (collapsed ? ' collapsed' : '')}>
        {mobileNav}
      </div>
      <div className={'mobile' + (collapsed ? ' collapsed' : '')}>
        <Hamburger toggled={collapsed} toggle={set} label="Show navigation" />
      </div>
    </header>
  )
}
