const gravatar = 'https://en.gravatar.com/userimage/28005451/a3ca95cc5f1512f840e62c81c1fa15c3.jpg?size=300'

export default {
  get entireSchema () {
    return {
      schema: this.schema,
      person: this.person,
      personSchema: this.personSchema,
      logoSchema: this.logoSchema,
      personSchemaForBlogPosting: this.personSchemaForBlogPosting,
      blogPosting: this.blogPosting,
      blogPostingSchema: this.blogPostingSchema,
      blog: this.blog,
      blogSchema: this.blogSchema,
      webSite: this.webSite,
      webSiteSchema: this.webSiteSchema,
      webPage: this.webPage,
      webPageSchema: this.webPageSchema,
      imageObject: this.imageObject,
      softwareSourceCode: this.softwareSourceCode,
      softwareSourceCodeSchema: this.softwareSourceCodeSchema
    }
  },

  get schema () {
    return {
      '@context': 'http://schema.org'
    }
  },

  /*
  PERSON
  ------
  N/A
*/
  get person () {
    return {
      '@id': 'AndrewZigler',
      description:
        'Andrew Zigler is a business director and front-end developer at an education company. He makes interactive learning experiences used by thousands of students annually.',
      email: 'me@andrewzigler.com',
      familyName: 'Zigler',
      givenName: 'Andrew',
      image: gravatar,
      jobTitle: 'Director of Business Operations and Front-End Developer',
      name: 'Andrew Zigler',
      mainEntityOfPage: 'https://www.andrewzigler.com/',
      url: 'https://www.andrewzigler.com/'
    }
  },

  get personSchema () {
    return { ...this.schema, '@type': 'Person', ...this.person }
  },

  get logoSchema () {
    return { ...this.schema, '@type': 'ImageObject' }
  },

  get personSchemaForBlogPosting () {
    return {
      ...this.schema,
      ...this.person,
      '@id': 'AndrewZiglerOrganization',
      '@type': 'Organization',
      familyName: undefined,
      givenName: undefined,
      jobTitle: undefined,
      ...{
        logo: {
          ...this.logoSchema,
          '@id': 'BlogLogo',
          url: gravatar
        }
      }
    }
  },

  /*
  BLOG POSTING
  ------------
  dateCreated: 'DATE',
  dateModified: 'DATE',
  datePublished: 'DATE',
  description: 'Blah',
  headline: 'headline',
  image: 'URL',
  mainEntityOfPage: 'THIS URL',
  name: 'NAME',
  articleBody: 'text',
  articleSection: 'category',
  wordCount: '??',
  url: 'URL'
*/
  get blogPosting () {
    return {
      author: this.personSchema,
      creator: this.personSchema,
      publisher: this.personSchemaForBlogPosting
    }
  },

  get blogPostingSchema () {
    return {
      ...this.schema,
      '@type': 'BlogPosting',
      ...this.blogPosting
    }
  },

  /*
  BLOG
  ----
  image: 'URL',
  thumbnailUrl: 'URL'
*/
  get blog () {
    return {
      '@id': 'AndrewZiglerBlog',
      author: this.personSchema,
      creator: this.ersonSchema,
      dateCreated: '2017-12-02T05:16:00+0000',
      datePublished: '2017-12-02T05:16:00+0000',
      description:
        "Since 2017, Andrew Zigler's blog has been providing unique insights into technology and culture. The blog also features comprehensive reviews and in-depth tutorials focusing on web development.",
      headline: "Andrew Zigler's Blog",
      mainEntityOfPage: 'https://www.andrewzigler.com/blog/',
      name: "Andrew Zigler's Blog",
      publisher: this.personSchema,
      url: 'https://www.andrewzigler.com/blog/'
    }
  },

  get blogSchema () {
    return { ...this.schema, '@type': 'Blog', ...this.blog }
  },

  /*
  WEB SITE
  --------
  N/A
*/
  get webSite () {
    return {
      '@id': 'andrewziglerdotcom',
      author: this.personSchema,
      creator: this.personSchema,
      description:
        'The portfolio website and technology blog of Andrew Zigler, an interdisciplinary web developer and business director.',
      hasPart: this.blogSchema,
      headline: 'Andrew Zigler',
      image: gravatar,
      mainEntity: this.personSchema,
      name: 'Andrew Zigler',
      thumbnailUrl: gravatar,
      url: 'https://www.andrewzigler.com/'
    }
  },

  get webSiteSchema () {
    return { ...this.schema, '@type': 'WebSite', ...this.webSite }
  },

  /*
  WEB PAGE
  --------
  description: 'DESCRIPTION FROM PAGE',
  headline: 'TITLE FROM PAGE',
  image: 'URL',
  name: 'TITLE FROM PAGE',
  thumbnailUrl: 'URL',
  url: 'URL FROM PAGE'
*/
  get webPage () {
    return {
      '@id': 'webpage',
      author: this.personSchema,
      creator: this.personSchema,
      isPartOf: this.webSiteSchema
    }
  },

  get webPageSchema () {
    return { ...this.schema, '@type': 'WebPage', ...this.webPage }
  },

  /*
  SOFTWARE SOURCE CODE
  --------------------
  codeRepository: 'URL',
  dateCreated: 'DATE',
  dateModified: 'DATE',
  datePublished: 'DATE',
  description: 'DESCRIPTION FROM PAGE',
  headline: 'TITLE FROM PAGE',
  image: 'URL',
  name: 'TITLE FROM PAGE',
  thumbnailUrl: 'URL',
  url: 'URL FROM PAGE'
*/
  get softwareSourceCode () {
    return {
      author: this.personSchema,
      creator: this.personSchema,
      isPartOf: this.webSiteSchema
    }
  },

  get softwareSourceCodeSchema () {
    return {
      ...this.schema,
      '@type': 'SoftwareSourceCode',
      ...this.softwareSourceCode
    }
  }
}
