import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Context = React.createContext({})

export default Context

const ContextProvider = ({ children }) => {
  const query = graphql`
  query Context {
    site(siteMetadata: {}) {
      siteMetadata {
        siteUrl,
        title,
        description,
        image,
        firstName,
        lastName,
        username,
        gender
      }
    }
  }
`
  const { site } = useStaticQuery(query)
  const [azContext, updateAzContext] = React.useState(site.siteMetadata)
  const context = {
    azContext,
    updateAzContext
  }
  return (
    <Context.Provider value={ context }>
      {children}
    </Context.Provider>
  )
}

export { ContextProvider }
